import React from "react";

import Navtab from '../components/Navbar/navbar';
import Footer from '../components/Footer.js';
import CasasBanner from '../components/casas/casasBanner';
import MainCas from '../components/casas/mainCas';


function Locales() {

    return (
        <div>
            <Navtab />
            <CasasBanner />
            <MainCas />
            <Footer />
        </div>

    );

}

export default Locales;