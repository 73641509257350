import React, { Component } from "react";
import Typed from "react-typed";
import { Row, Col, Button, Card, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";

import "../../../src/index.css";
import "../../assets/css/theme.css";

import homeicon from "../../assets/img/line-icons/pricing-house-1.svg";
import comercialicon from "../../assets/img/line-icons/pricing-house-2.svg";
import terrainicon from "../../assets/img/line-icons/growth.svg";

import Local1 from "../../img/Local1.jpeg";
const Intro = () => {
  return (
    // <Container fluid className="text-center bg-light">
    //   <br/>
    // <Container>
    //   <Row className="justify-content-center align-items-center ">
    //     <Col lg={4} className="jcc">
    //     <Card style={{ width: '18rem' }} className="lift mb-4">
    //       {/* <Card.Img variant="top" src={Local1} /> */}

    //       <Card.Body>
    //         <Card.Title>Buenas Ubicaciones</Card.Title>
    //         <Card.Text>
    //           Ya sea que busques un local comercial, terreno o casa, tenemos ubicaciones para ti.
    //         </Card.Text>
    //         {/* <Button variant="primary">Go somewhere</Button> */}
    //       </Card.Body>
    //     </Card>
    //     </Col>

    //     <Col lg={4} className="jcc">
    //     <Card style={{ width: '18rem' }} className="lift mb-4">
    //       {/* <Card.Img variant="top" src={Local1} /> */}
    //       <Card.Body>
    //         <Card.Title>Excelentes Condiciones</Card.Title>
    //         <Card.Text>
    //           Todas nuestras ubicaciones las recibes al 100%. Ningún detalle, lísta para lo que quieras.
    //         </Card.Text>
    //         {/* <Button variant="primary">Go somewhere</Button> */}
    //       </Card.Body>
    //     </Card>
    //     </Col>

    //     <Col lg={4} className="jcc">
    //     <Card style={{ width: '18rem' }} className="lift mb-4">
    //       {/* <Card.Img variant="top" src={Local1} /> */}
    //       <Card.Body>
    //         <Card.Title>Atención Post-renta</Card.Title>
    //         <Card.Text>
    //           No te abandonamos. Si llegas a tener algún problema, lo solucionamos rápidamente.
    //         </Card.Text>
    //         {/* <Button variant="primary">Go somewhere</Button> */}
    //       </Card.Body>
    //     </Card>
    //     </Col>

    //   </Row>
    //   </Container>
    // </Container>

    <Container className="text-center py-7">
      <div
        className="row mb-4"
        // data-zanim-timeline="{}"
        // data-zanim-trigger="scroll"
      >
        <div className="col">
          <div className="overflow-hidden">
            <h2
              className="fs-md-5"
              // data-zanim-xs='{"duration":"1.5","delay":0}'
            >
              Tu aliado
            </h2>
          </div>
          <div className="overflow-hidden">
            <p
              className="fs--1 text-uppercase text-black ls-0 mb-0"
              data-zanim-xs='{"duration":"1.5","delay":0.1}'
            >
              Para cualquier proyecto que tengas en mente
            </p>
          </div>
          <div className="overflow-hidden">
            <hr
              className="hr-short border-black"
              data-zanim-xs='{"duration":"1.5","delay":0.2}'
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="col-sm-6 col-lg-4 mb-3"
          // data-zanim-timeline="{}"
          // data-zanim-trigger="scroll"
        >
          <div className="overflow-hidden">
            <div className="service-item p-3 p-md-4 h-100">
              <div className="overflow-hidden">
                <div
                  className="px-4"
                  data-zanim-xs='{"duration":"1.5","delay":"0.3"}'
                >
                  <img
                    className="service-icon"
                    style={{ color: "black" }}
                    src={homeicon}
                    alt=""
                  />
                </div>
              </div>
              <div className="overflow-hidden">
                <h5
                  className="font-weight-normal ls-2 mb-2"
                  data-zanim-xs='{"duration":"1.5","delay":"0.4"}'
                >
                  Habitacional
                </h5>
              </div>
              <div className="overflow-hidden">
                <p
                  className="font-weight-normal"
                  data-zanim-xs='{"duration":"1.5","delay":"0.5"}'
                >
                  Propiedades desde casa habitación hasta departamentos,{" "}
                  <br className="d-block d-sm-none" /> amuebladas o sin amueblar
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-sm-6 col-lg-4 mb-3"
          // data-zanim-timeline="{}"
          // data-zanim-trigger="scroll"
        >
          <div className="overflow-hidden">
            <div className="service-item p-3 p-md-4 h-100">
              <div className="overflow-hidden">
                <div
                  className="px-4"
                  data-zanim-xs='{"duration":"1.5","delay":"0.3"}'
                >
                  <img className="service-icon" src={comercialicon} alt="" />
                </div>
              </div>
              <div className="overflow-hidden">
                <h5
                  className="font-weight-normal ls-2 mb-2"
                  data-zanim-xs='{"duration":"1.5","delay":"0.4"}'
                >
                  Comercial
                </h5>
              </div>
              <div className="overflow-hidden">
                <p
                  className="font-weight-normal"
                  data-zanim-xs='{"duration":"1.5","delay":"0.5"}'
                >
                  Propiedades con excelente ubicación,
                  <br className="d-block d-sm-none" /> buen tráfico y
                  exposición. Desde locales y oficinas
                  <br className="d-block d-sm-none" /> hasta dark kitchens.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-sm-6 col-lg-4 mb-3"
          // data-zanim-timeline="{}"
          // data-zanim-trigger="scroll"
        >
          <div className="overflow-hidden">
            <div className="service-item p-3 p-md-4 h-100">
              <div className="overflow-hidden">
                <div
                  className="px-4"
                  data-zanim-xs='{"duration":"1.5","delay":"0.4"}'
                >
                  <img className="service-icon" src={terrainicon} alt="" />
                </div>
              </div>
              <div className="overflow-hidden">
                <h5
                  className="font-weight-normal ls-2 mb-2"
                  data-zanim-xs='{"duration":"1.5","delay":"0.5"}'
                >
                  terrenos
                </h5>
              </div>
              <div className="overflow-hidden">
                <p
                  className="font-weight-normal"
                  data-zanim-xs='{"duration":"1.5","delay":"0.6000000000000001"}'
                >
                  Buenos terrenos para el proyecto que tengas{" "}
                  <br className="d-block d-sm-none" />
                  en mente, habitacional o comercial
                  {/* <br className="d-block d-sm-none" /> businesses that will sustain in the future. */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Intro;
