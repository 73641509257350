import React, { Component } from "react";
import Typed from "react-typed";
import { Row, Col, Button, Card, Container } from "react-bootstrap";
import Iframe from "react-iframe";
const Contact = () => {
  return (
    // <Container fluid className="text-center" id="contacto">
    //   <br />
    //   <Container>
    //     <Row className="justify-content-center align-items-center ">
    //       <Col lg={6} className="jcc fdc">
    //         <h4 className="mb-4">Contacto</h4>

    //         <a href="https://wa.me/message/4U6JST5UXMNFJ1">81 1690 9429</a>

    //         <a href="mailto: tropezcapital@gmail.com" className="mb-4">
    //           tropezcapital@gmail.com
    //         </a>

    //         <p>
    //           Av. Luis Donaldo Colosio Murrieta 1571, Barrio San Luis, 64100
    //           Monterrey, N.L.
    //         </p>
    //       </Col>

    //       <Col lg={6} className="jcc">
    //         <Iframe
    //           url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d898.2140014636384!2d-100.3878825708007!3d25.77531929897691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86629125897e39b9%3A0x17310ad7b498c38d!2sAv.%20Luis%20Donaldo%20Colosio%20Murrieta%201571%2C%20Mirasol%201o.%2C%2064100%20Monterrey%2C%20N.L.!5e0!3m2!1ses!2smx!4v1616451113061!5m2!1ses!2smx"
    //           width="100%"
    //           height="450px"
    //           id="myId"
    //           className="myClassname"
    //           display="initial"
    //           position="relative"
    //         />
    //       </Col>
    //     </Row>
    //   </Container>
    //   <br />
    // </Container>

    <section id="contacto">
      <div className="container">
        <div className="row">
          <div
            className="col text-center mb-4"
            // data-zanim-timeline="{}"
            // data-zanim-trigger="scroll"
          >
            <div className="overflow-hidden">
              <h2
                className="fs-md-5"
                // data-zanim-xs='{"duration":1.5,"delay":0}'
              >
                Contáctanos
              </h2>
            </div>
            <div className="overflow-hidden">
              <p
                className="fs--1 text-uppercase text-black ls-0 mb-0"
                data-zanim-xs='{"duration":1.5,"delay":0.1}'
              >
                Estaremos felices de escucharte
              </p>
            </div>
            <div className="overflow-hidden">
              <hr
                className="hr-short border-black"
                data-zanim-xs='{"duration":1.5,"delay":0.2}'
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div
            className="
        col-lg-6
        mb-5 mb-lg-0
        d-flex
        flex-column
        justify-content-between
      "
          >
            <div className="row">
              <div className="col-12">
                <h5 className="mb-3">Conecta con nosotros</h5>
              </div>
              <div className="col-auto mb-2 mb-sm-0" style={{ width: "190px" }}>
                <div className="row">
                  <div className="col-1"></div>
                  <div className="col px-2">
                    {/* <p className="mb-1 text-700">
                      <strong>Reign Studio</strong>
                    </p>
                    <p className="mb-0 text-600">
                      590, Castro St,
                      <br />
                      United States
                    </p> */}
                    <a
                      href="https://www.facebook.com/TropezCapital"
                      target="__blank"
                      className="text-600"
                    >
                      Facebook
                    </a>
                    <br />
                    <a
                      href="https://www.instagram.com/tropez_capital/"
                      target="__blank"
                      className="text-600"
                    >
                      Instagram
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-auto" style={{ width: "205px" }}>
                <div className="row mb-2 mb-sm-1 px-4">
                  <div className="col-1"></div>
                  <div className="col px-2">
                    {/* <a className="text-600" href="tel:526853300">
                      526 853 300{" "}
                    </a>
                    <br />
                    <a className="text-600" href="tel:526853300">
                      478 484 500
                    </a> */}

                    <a
                      href="https://wa.me/message/4U6JST5UXMNFJ1"
                      className="text-600"
                    >
                      81 1690 9429
                    </a>
                    <br />
                    <a
                      href="mailto: tropezcapital@gmail.com"
                      className="text-600"
                    >
                      tropezcapital@gmail.com
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="marker-content py-3">
              <h5>oficinas</h5>
              <p>
                Av. Luis Donaldo Colosio Murrieta 1571, Barrio San Luis,
                <br /> 64100, Monterrey, N.L. MX.
              </p>
            </div>
          </div>
          <div className="col-lg-6 jcc">
            <Iframe
              url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d898.2140014636384!2d-100.3878825708007!3d25.77531929897691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86629125897e39b9%3A0x17310ad7b498c38d!2sAv.%20Luis%20Donaldo%20Colosio%20Murrieta%201571%2C%20Mirasol%201o.%2C%2064100%20Monterrey%2C%20N.L.!5e0!3m2!1ses!2smx!4v1616451113061!5m2!1ses!2smx"
              width="100%"
              height="400px"
              id="myId"
              className="myClassname"
              display="initial"
              position="relative"
            />

            {/* <h5 className="mb-3">mandanos un mensaje</h5>
            <form className="zform text-left" method="post">
              <input
                type="hidden"
                name="to"
                value="username@domain.extension"
              />
              <div className="form-group mb-3">
                <input
                  className="fs-0 form-control"
                  type="text"
                  placeholder="Your Name"
                  required="required"
                />
              </div>
              <div className="form-group mb-3">
                <input
                  className="fs-0 form-control"
                  type="email"
                  placeholder="Email Address"
                  required="required"
                />
              </div>
              <div className="form-group mb-3">
                <textarea
                  className="fs-0 form-control contact-message"
                  rows="8"
                  placeholder="Type your message here"
                  required="required"
                ></textarea>
              </div>
              <div className="zform-feedback my-2"></div>
              <button
                className="btn btn-block btn-dark hvr-sweep-top"
                type="submit"
              >
                Give us a shot
              </button>
            </form> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
