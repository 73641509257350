import React, { Component, useEffect, useState } from "react";
import { Navbar, Nav, Button, NavDropdown, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from "react-router-dom";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import logo from "../../img/logo_transp.png";

import { HashLink as Link } from "react-router-hash-link";
import "../../../src/index.css";

import "../../assets/css/theme.css";

function Navtab() {
  let history = useHistory();
  const { scrollYProgress } = useViewportScroll();
  const [transp, setTransp] = useState(0.1);

  const [scrollTop, setScrollTop] = useState();
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
      if (scrollYProgress.current < 0.039) {
        setTransp(0.1);
      } else {
        setTransp(0.92);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  function clickLoc() {
    history.push("/locales");
  }

  function clickCas() {
    history.push("/casas");
  }

  function clickTer() {
    history.push("/terrenos");
  }

  return (
    <Navbar
      expand="lg"
      onToggle={(resp) => {
        if (resp === true) {
          setTransp(0.92);
        } else {
          if (scrollYProgress.current < 0.039) {
            setTransp(0.1);
          } else {
            setTransp(0.92);
          }
        }
      }}
      style={{
        backgroundColor: `rgba(10, 10, 10,${transp} )`,
        transition: "ease-in-out, 0.5s",
        // paddingBottom: "0px",
      }}
      className="navbar-dark fixed-top p-0"
    >
      <div className="container px-3">
        <Navbar.Brand>
          <Link
            className="navBrand navbar-brand font-weight-normal ls-1"
            to="/"
          >
            <img
              src={logo}
              style={{
                height: "2rem",
                width: "auto",
                // marginRight: "-0.7rem",
                // marginTop: "-0.5rem",
              }}
            />
            {/* <span className="navbar-brand-i"></span> */}
            Tropez Capital
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav" className="mb-2">
          <Nav className="navbar-nav ml-auto">
            <NavDropdown
              title="Portafolio"
              id="basic-nav-dropdown"
              className="nav-item nav-link-text"
              style={{
                marginTop: "0.5rem",
                fontSize: 13,
                fontWeight: "500",
                transition: "ease-in-out 0.3s !important",
              }}
            >
              <NavDropdown.Item
                className="nav-link"
                onClick={clickLoc}
                style={{ fontSize: 12 }}
              >
                Locales
              </NavDropdown.Item>
              <NavDropdown.Item
                className="nav-link"
                onClick={clickTer}
                style={{ fontSize: 12 }}
              >
                Terrenos
              </NavDropdown.Item>
              <NavDropdown.Item
                className="nav-link"
                onClick={clickCas}
                style={{ fontSize: 12 }}
              >
                Casas
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link className="nav-item nav-link-text">
              <Link
                to="/#contacto"
                className="hvr-sweep-top nav-link"
                style={{
                  fontSize: 13,
                  fontWeight: "500",
                  transition: "ease-in-out 0.3s",
                }}
              >
                Contacto
              </Link>
            </Nav.Link>
          </Nav>

          <a
            href="https://wa.me/message/4U6JST5UXMNFJ1"
            target="__blank"
            className="nav-item nav-link"
            style={{
              fontSize: 13,
              color: "#FFF",
              fontWeight: "500",
              transition: "ease-in-out 0.3s",
            }}
          >
            81 1690 9429
          </a>
        </Navbar.Collapse>
      </div>
    </Navbar>
    // <nav className="navbar navbar-expand-lg navbar-dark navbar-theme fixed-top p-0">
    //   <div className="container px-3">
    //     <a className="navbar-brand font-weight-normal ls-1" href="../index.html">
    //       <span className="navbar-brand-i">Tropez</span>Capital
    //     </a>
    //     <button
    //       className="navbar-toggler collapsed"
    //       type="button"
    //       data-toggle="collapse"
    //       data-target="#navbarSupportedContent"
    //       aria-controls="navbarSupportedContent"
    //       aria-expanded="false"
    //       aria-label="Toggle navigation"
    //     >
    //       <span className="navbar-toggler-icon"></span>
    //     </button>

    //     <div className="collapse navbar-collapse" id="navbarSupportedContent">
    //       <ul className="navbar-nav ml-auto">
    //         <li className="nav-item dropdown dropdown-on-hover">
    //           <a
    //             className="nav-link dropdown-toggle"
    //             id="navbarDropdownMenuLink1"
    //             href="#"
    //             data-toggle="dropdown"
    //             aria-haspopup="true"
    //             aria-expanded="false"
    //           >
    //             <span className="nav-link-text">Home</span>
    //           </a>
    //           <div
    //             className="dropdown-menu dropdown-menu-right"
    //             aria-labelledby="navbarDropdownMenuLink1"
    //           >
    //             <a className="dropdown-item" href="../pages/one-page.html">
    //               One page
    //             </a>

    //             <a className="dropdown-item" href="../pages/gradient-classic.html">
    //               Gradient classic
    //             </a>
    //           </div>
    //         </li>
    //         <li className="nav-item dropdown dropdown-on-hover">
    //           <a
    //             className="nav-link dropdown-toggle"
    //             id="navbarDropdownMenuLink2"
    //             href="#"
    //             data-toggle="dropdown"
    //             aria-haspopup="true"
    //             aria-expanded="false"
    //           >
    //             <span className="nav-link-text">Pages</span>
    //           </a>
    //           <div
    //             className="dropdown-menu dropdown-menu-right dropdown-pages py-3"
    //             aria-labelledby="navbarDropdownMenuLink2"
    //           >
    //             <div className="row">
    //               <div className="col-6 col-md-auto px-2">
    //                 <ul className="nav flex-column">
    //                   <li className="nav-item">
    //                     <a
    //                       className="nav-link font-weight-medium"
    //                       href="../pages/about.html"
    //                     >
    //                       About
    //                     </a>
    //                   </li>
    //                   <li className="nav-item">
    //                     <a
    //                       className="nav-link font-weight-medium"
    //                       href="../pages/services.html"
    //                     >
    //                       Services
    //                     </a>
    //                   </li>
    //                 </ul>
    //               </div>

    //               <div className="col-6 col-md-auto px-2">
    //                 <ul className="nav flex-column">
    //                   <li className="nav-item">
    //                     <a
    //                       className="nav-link font-weight-medium"
    //                       href="../pages/contact.html"
    //                     >
    //                       Contact
    //                     </a>
    //                   </li>
    //                   <li className="nav-item">
    //                     <a
    //                       className="nav-link font-weight-medium"
    //                       href="../pages/404.html"
    //                     >
    //                       404
    //                     </a>
    //                   </li>
    //                 </ul>
    //               </div>
    //               <div className="col-6 col-md-auto px-2">
    //                 <ul className="nav flex-column">
    //                   <li className="nav-item">
    //                     <a
    //                       className="nav-link font-weight-medium"
    //                       href="../pages/work-wide.html"
    //                     >
    //                       Work wide
    //                     </a>
    //                   </li>
    //                   <li className="nav-item">
    //                     <a
    //                       className="nav-link font-weight-medium"
    //                       href="../pages/work-single.html"
    //                     >
    //                       Work single
    //                     </a>
    //                   </li>
    //                 </ul>
    //               </div>
    //             </div>
    //           </div>
    //         </li>
    //         <li className="nav-item dropdown dropdown-on-hover">
    //           <a
    //             className="nav-link dropdown-toggle"
    //             id="navbarDropdownMenuLink3"
    //             href="#"
    //             data-toggle="dropdown"
    //             aria-haspopup="true"
    //             aria-expanded="false"
    //           >
    //             <span className="nav-link-text">Components</span>
    //           </a>
    //           <div
    //             className="
    //                 dropdown-menu dropdown-menu-right dropdown-components
    //                 py-3
    //               "
    //             aria-labelledby="navbarDropdownMenuLink3"
    //           >
    //             <div className="row">
    //               <div className="col-6 col-md-auto px-2">
    //                 <ul className="nav flex-column">
    //                   <li className="nav-item">
    //                     <a
    //                       className="nav-link font-weight-medium"
    //                       href="../components/accordion.html"
    //                     >
    //                       Accordion
    //                     </a>
    //                   </li>
    //                   <li className="nav-item">
    //                     <a
    //                       className="nav-link font-weight-medium"
    //                       href="../components/alerts.html"
    //                     >
    //                       Alerts
    //                     </a>
    //                   </li>
    //                 </ul>
    //               </div>
    //               <div className="col-6 col-md-auto px-2">
    //                 <ul className="nav flex-column">
    //                   <li className="nav-item">
    //                     <a
    //                       className="nav-link font-weight-medium"
    //                       href="../components/countup.html"
    //                     >
    //                       Countup
    //                     </a>
    //                   </li>
    //                   <li className="nav-item">
    //                     <a
    //                       className="nav-link font-weight-medium"
    //                       href="../components/dropdowns.html"
    //                     >
    //                       Dropdowns
    //                     </a>
    //                   </li>
    //                 </ul>
    //               </div>
    //               <div className="col-6 col-md-auto px-2">
    //                 <ul className="nav flex-column">
    //                   <li className="nav-item">
    //                     <a
    //                       className="nav-link font-weight-medium"
    //                       href="../components/inline-player.html"
    //                     >
    //                       Inline player
    //                     </a>
    //                   </li>
    //                   <li className="nav-item">
    //                     <a
    //                       className="nav-link font-weight-medium"
    //                       href="../components/lightbox.html"
    //                     >
    //                       Lightbox
    //                     </a>
    //                   </li>
    //                 </ul>
    //               </div>
    //               <div className="col-6 col-md-auto px-2">
    //                 <ul className="nav flex-column">
    //                   <li className="nav-item">
    //                     <a
    //                       className="nav-link font-weight-medium"
    //                       href="../components/spacing.html"
    //                     >
    //                       Spacing
    //                     </a>
    //                   </li>
    //                   <li className="nav-item">
    //                     <a
    //                       className="nav-link font-weight-medium"
    //                       href="../components/tables.html"
    //                     >
    //                       Tables
    //                     </a>
    //                   </li>
    //                 </ul>
    //               </div>
    //             </div>
    //           </div>
    //         </li>
    //         <li className="nav-item dropdown dropdown-on-hover">
    //           <a
    //             className="nav-link dropdown-toggle"
    //             id="navbarDropdownMenuLink4"
    //             href="#"
    //             data-toggle="dropdown"
    //             aria-haspopup="true"
    //             aria-expanded="false"
    //           >
    //             <span className="nav-link-text">Docs</span>
    //           </a>
    //           <div
    //             className="dropdown-menu dropdown-menu-right"
    //             aria-labelledby="navbarDropdownMenuLink4"
    //           >
    //             <a className="dropdown-item" href="../docs/getting-started.html">
    //               Getting started
    //             </a>
    //             <a className="dropdown-item" href="../docs/file-structure.html">
    //               File structure
    //             </a>
    //           </div>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    // </nav>
  );
}

export default Navtab;
