import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Container,
  Carousel,
  Spinner,
  Modal,
} from "react-bootstrap";
import { db, storage } from "../../firebase";
import Iframe from "react-iframe";
import { HashLink as Link } from "react-router-hash-link";
import sadicon from "../../assets/img/line-icons/sad.svg";

const MainLoc = () => {
  const [locs, setLocs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState([]);

  const GetLocs = async () => {
    var locales = [];
    var arShow = [];

    setLoading(true);

    await db
      .collection("locales")
      .where("active", "==", true)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((docc) => {
          locales.push({ ...docc.data(), id: docc.id });
          arShow.push(false);
        });
        setLocs(locales);
        setShow(arShow);
      })
      .catch((err) => {
        console.log(`Encountered error: ${err}`);
      });

    setLoading(false);
  };

  useEffect(() => {
    GetLocs();
    console.log("collecting...");
  }, []);

  var series = 0;

  function handleOpen(s) {
    // s.preventDefault();
    var array = [];
    var ii = 0;
    for (ii = 0; ii < show.length; ii++) {
      if (ii == s) {
        array.push(true);
      } else {
        array.push(false);
      }
    }
    setShow(array);
  }

  function handleClose() {
    var f = 0;
    var ary = [];
    for (f = 0; f < show.length; f++) {
      ary.push(false);
    }
    setShow(ary);
  }

  return (
    <Container>
      <br />
      <p className="font-weight-light text-uppercase">
        {locs.length} resultado(s)
      </p>
      <Row>
        {loading ? (
          <Col lg={3} md={6} className="jcc mb-4">
            <Spinner animation="border" role="status">
              <span className="sr-only">Cargando...</span>
            </Spinner>
            <br />
          </Col>
        ) : locs.length == 0 ? (
          <Col
            sm={12}
            className="mb-4 acc jcc"
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="process-icon mb-3" src={sadicon} alt="" />
            <h3>No hay locales disponibles por el momento</h3>
          </Col>
        ) : (
          locs.map((loc) => {
            return (
              <Col lg={3} md={6} className="jcc">
                <Card
                  // style={{ , backgroundColor: "red" }}
                  className="mb-4 itemCard"
                >
                  <Carousel>
                    {loc.fotos.map((foto) => {
                      return (
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src={foto}
                            alt="First slide"
                          />
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>

                  <Card.Body className="fdc spb">
                    <div>
                      <small>{loc.State}</small>
                      <Card.Title>{loc.Nombre}</Card.Title>
                      <Card.Text className="mb-4">{loc.Ubicacion}</Card.Text>
                    </div>

                    <Button
                      block
                      // variant="primary"
                      onClick={() => handleOpen(locs.indexOf(loc))}
                      className="btn btn-outline-dark hvr-sweep-top mt-5 px-4"
                    >
                      Ver más
                    </Button>
                  </Card.Body>
                </Card>

                <Modal
                  show={show[locs.indexOf(loc)]}
                  onHide={handleClose}
                  animation={true}
                  contentClassName="transparent"
                  centered
                  size="lg"
                >
                  <Card style={{ borderRadius: 15 }}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <div className="text-center">
                        <h4 className="font-weight-light">{loc.Nombre}</h4>

                        {loc.Ubicacion}
                      </div>

                      <Carousel>
                        {loc.fotos.map((foto) => {
                          return (
                            <Carousel.Item
                            // style={{ maxHeight: "30rem", width: "auto" }}
                            >
                              <img
                                className="d-block w-100"
                                src={foto}
                                alt="First slide"
                              />
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>

                      <Row className="mt-3">
                        <Col md={6} sm={12}>
                          <Iframe
                            url={loc.locUrl}
                            width="100%"
                            height="100%"
                            id="myId"
                            className="myClassname"
                            display="initial"
                            position="relative"
                          />
                        </Col>

                        <Col md={6} sm={12} className="py-2">
                          <Container>
                            <p>Mt^2: {loc.mt2}</p>
                            <p>Baños: {loc.banos}</p>
                            <p>Medios Baños: {loc.medioBanos}</p>
                            <p>{loc.nota}</p>
                          </Container>
                        </Col>
                      </Row>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button
                        className="btn btn-outline-danger hvr-sweep-top"
                        onClick={handleClose}
                      >
                        Cerrar
                      </Button>
                      <Link
                        className="btn btn-outline-primary hvr-sweep-top"
                        to="/#contacto"
                      >
                        Solicitar información
                      </Link>
                    </Modal.Footer>
                  </Card>
                </Modal>
              </Col>
            );
          })
        )}
      </Row>
    </Container>
  );
};

export default MainLoc;
