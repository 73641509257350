import React from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";
import iphone1 from "../../img/phone.png";

const imageStyle = {
  width: "100%",
  // position: "-webkit-sticky",
  // position: "fixed",
  // height: "100rem",
  top: "0.5rem",
  left: "0.5rem",
  overflow: "hidden",
  padding: "1rem",
  boxSizing: "border-box",
};

function AnimatedSection() {
  // const reff1 = React.useRef()
  // const { scrollYProgress } = useElementScroll(reff1)
  const { scrollYProgress } = useViewportScroll();
  const xPosAnim = useTransform(scrollYProgress, [0, 0.55, 1], [-220, 0, 20]);
  const scaleAnim = useTransform(scrollYProgress, [0, 0.8, 1], [0.1, 1, 1]);
  // const centerdiv = document.getElementById('centerDiv');

  // scrollYProgress.onChange((x) => {
  //     centerdiv.scrollIntoView(true)
  // });
  return (
    <Container
      // fluid
      className="landing-page-container2 d-flex flex-column"
      // ref={reff1}
      id="features"
    >
      <Row className="align-items-center m-0">
        <Col
          md={6}
          sm={12}
          className="align-items-center justify-content-center text-center respad"
        >
          <motion.div
            style={{
              ...imageStyle,
              // y: yPosAnim,
              x: xPosAnim,
            }}
          >
            <img src={iphone1} alt="iphone1" className="img1 pl-3 pr-3" />
          </motion.div>
        </Col>

        <Col
          md={6}
          sm={12}
          className="align-items-center justify-content-center text-center respad mt-4"
        >
          <h2 className="fs-md-5">Sencillo</h2>
          <p>
            Checa 24/7 365 todos nuestros activos disponibles, en caso de que te
            interese alguno, contáctanos. Te atenderemos con gusto.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
export default AnimatedSection;
// const envelopeStyle = {
//   width: "28rem",
//   height: "15rem",
//   scale: 1,
// //   position: "fixed",
//   top: "10rem",
//   left: "calc(50% - 14rem)",
//   boxShadow: `rgba(0, 0, 0, 0.5) 0px 0px 150px 10px`,
// };

// const frontfaceStyle = {
//   width: "100%",
//   height: "100%",
//   backgroundColor: "#debda1",
//   position: "absolute",
//   left: 0,
//   top: 0,
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
// };

// function Envelope({ children }) {
//   const [ffLayer, setFfLayer] = useState(0);
//   const { scrollYProgress } = useViewportScroll();
//   const scaleAnim = useTransform(scrollYProgress, [0, 0.5, 1], [1, 1.2, 0.8]);
//   const yPosAnim = useTransform(scrollYProgress, [10, 0.5, 1], [0, 100, 200]);
//   const xPosAnim = useTransform(scrollYProgress, [10, 0.5, 1], [0, 100, 200]);
//   const zRotAnim = useTransform(scrollYProgress, [0, 0.5, 1], [0, 3, 0]);

//   scrollYProgress.onChange((x) => {
//     setFfLayer(x > 0.4 ? -1 : 0);
//   });

//   return (
//     <motion.div
//       style={{
//         ...envelopeStyle,
//         scale: scaleAnim,
//         y: yPosAnim,
//         x: xPosAnim,
//         rotateZ: zRotAnim,
//       }}
//     >
//       {children}
//       <div style={{ ...frontfaceStyle, zIndex: ffLayer }}>
//         <button onClick={() => window.scrollTo(0, 1500)}>Open Me</button>
//       </div>
//     </motion.div>
//   );
// }

// const letterSceneStyle = {
//   height: "100%",
// };

// export default function LetterScene() {
//   return (
//     <div style={letterSceneStyle}>
//       <Envelope>
//         <Letter />
//       </Envelope>
//     </div>
//   );
// }
