import React, { Component } from "react";
import Typed from "react-typed";
import { Row, Col, Button, Card, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/theme.css";

const Banner = () => {
  return (
    <div className="overflow-hidden banner w-100">
      <div className="mask-dark w-100 d-flex align-items-center ">
        <div class="container d-flex align-items-center ">
          <div class="d-flex align-items-center pt-10 pb-8">
            <div class="header-text">
              <div class="overflow-hidden">
                <h3
                  class="text-white"
                  data-zanim-xs='{"duration":2,"delay":0}'
                  style={{ fontWeight: "400" }}
                >
                  Tropez, <br class="d-block d-sm-none" />
                  <Typed
                    strings={[
                      "buenas ubicaciones",
                      "excelente servicio",
                      "profesionales",
                      "tu aliado",
                    ]}
                    typeSpeed={40}
                    backSpeed={50}
                    className="typed-text"
                    loop
                  />
                </h3>
              </div>
              <div class="overflow-hidden">
                <p
                  class="text-uppercase text-400 ls-2 mt-2"
                  data-zanim-xs='{"duration":2,"delay":0.1}'
                  style={{ fontWeight: "500" }}
                >
                  Queremos brindarte de bienes raíces con ubicaciones
                  excelentes,
                  <br class="d-none d-sm-block" />
                  profesionales y para el propósito que necesites.
                </p>
              </div>
              {/* <div data-zanim-xs='{"from":{"opacity":0,"y":30},"to":{"opacity":1,"y":0},"duration":1.5,"delay":0.5}'>
                <a
                  class="btn btn-sm btn-outline-light hvr-sweep-top mt-5 px-4"
                  href="../pages/services.html"
                >
                  our services
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
