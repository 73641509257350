import React, { Component } from "react";

import Local2 from "../../img/Local2.jpeg";

import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/theme.css";

function LocalBanner() {
  return (
    <div>
      <section
        className="text-center overflow-hidden py-0 localesbanner"
        id="top"
        // data-zanim-timeline="{}"
        // data-zanim-trigger="scroll"
      >
        <div className="container-fluid mask-dark">
          <div className="header-overlay"></div>
          <div className="position-relative pt-10 pb-8">
            <div className="header-text">
              <div className="overflow-hidden">
                <h1
                  className="display-3 text-white font-weight-light ls-1"
                  //   data-zanim-xs='{"duration":2,"delay":0}'
                >
                  Locales
                </h1>
              </div>
              <div className="overflow-hidden">
                <div
                  className="d-flex justify-content-center"
                  //   data-zanim-xs='{"duration":2,"delay":0.1}'
                >
                  <p className="text-uppercase ls-1 text-white ">
                    Te proporcionamos locales comerciales estratégicos para
                    hacer crecer tu negocio
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LocalBanner;
