import React from "react";
function CasasBanner() {
  return (
    <div>
      <section
        className="text-center overflow-hidden py-0 casasbanner"
        id="top"
        // data-zanim-timeline="{}"
        // data-zanim-trigger="scroll"
      >
        <div className="container-fluid mask-dark">
          <div className="header-overlay"></div>
          <div className="position-relative pt-10 pb-8">
            <div className="header-text">
              <div className="overflow-hidden">
                <h1
                  className="display-3 text-white font-weight-light ls-1"
                  //   data-zanim-xs='{"duration":2,"delay":0}'
                >
                  Casas
                </h1>
              </div>
              <div className="overflow-hidden">
                <div
                  className="d-flex justify-content-center"
                  //   data-zanim-xs='{"duration":2,"delay":0.1}'
                >
                  <p className="text-uppercase ls-1 text-white ">
                    Te ofrecemos casas en excelentes condiciones, ubicaciones
                    seguras y privadas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CasasBanner;
