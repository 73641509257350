import React from "react";

import Navtab from '../components/Navbar/navbar';
import Footer from '../components/Footer.js';
import LocalBanner from '../components/locales/localesBanner';
import MainLoc from '../components/locales/mainLoc';
function Locales() {

    return (
        <div>
            <Navtab />
            <LocalBanner />
            <MainLoc />
            <Footer />
        </div>

    );

}

export default Locales;