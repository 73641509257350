import React from "react";

import Navtab from '../components/Navbar/navbar';
import Footer from '../components/Footer.js';

import TerrenosBanner from '../components/terrenos/terrenosBanner';
import MainTer from '../components/terrenos/mainTer';

function Locales() {

    return (
        <div>
            <Navtab />
            <TerrenosBanner />
            <MainTer />
            <Footer />
        </div>

    );

}

export default Locales;