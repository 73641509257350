import firebase from "firebase/app";
// import firebaseAnalitics from "firebase/analytics";
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/storage';

var firebaseConfig = {
    apiKey: "AIzaSyDms5xZkpe5hobEgud_53kOKbPb3CoOiys",
    authDomain: "tropezcapital-2fc43.firebaseapp.com",
    databaseURL: "https://tropezcapital-2fc43.firebaseio.com",
    projectId: "tropezcapital-2fc43",
    storageBucket: "tropezcapital-2fc43.appspot.com",
    messagingSenderId: "983783798098",
    appId: "1:983783798098:web:9a332bafa86336f7e7368b",
    measurementId: "G-XZ572SPM76"

    // apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}` ,
    // authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
    // databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
    // projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
    // storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
    // messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGINGSID}`,
    // appId: `${process.env.REACT_APP_FIREBASE_APPID}`,
    // measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENTID}`
  };
  

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
 }else {
    firebase.app();
 }

 firebase.analytics();

export const db = firebase.firestore();

export const storage = firebase.storage();