import React, { Component } from "react";

import Navtab from "../components/Navbar/navbar";
import Banner from "../components/landing/banner";
import Intro from "../components/landing/intro";
import Footer from "../components/Footer.js";
import Contact from "../components/landing/contact.js";

import AnimatedSection from "../components/landing/animated.js";
import Proceso from "../components/landing/proceso";
function Landing() {
  return (
    <div>
      <Navtab />
      <Banner />
      <Intro />
      <AnimatedSection />
      <Proceso />
      <Contact />
      <Footer />
    </div>
  );
}

export default Landing;
