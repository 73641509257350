import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import './firebase.js'

import { HashRouter, Route, Switch } from "react-router-dom";
import Landing from './layouts/landing';
import Locales from './layouts/locales';

import Terrenos from './layouts/terrenos';
import Casas from './layouts/casas';

ReactDOM.render(
  <HashRouter>
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route exact path="/locales" component={Locales} />
      <Route exact path="/terrenos" component={Terrenos} />
      <Route exact path="/casas" component={Casas} />
    </Switch>
 </HashRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
