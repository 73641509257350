import React from "react";

import Terra from "../../img/terra.jpg";
function TerrenosBanner() {
  return (
    // <div>
    //     <div className="spacing"></div>

    //     <h3 className="ml-4">Terrenos en ubicaciones estratégicas para tus proyectos</h3>
    //     <p className="ml-4">Te proporcionamos ubicaciones estratégicas para tu desarrollo</p>
    //     <div id="introLoc">
    //     <img src={Terra} alt="" className="imgCo" />
    //     </div>

    //     <h3 className="ml-4">Terrenos</h3>
    // </div>

    <div>
      <section
        className="text-center overflow-hidden py-0 terrabanner"
        id="top"
        // data-zanim-timeline="{}"
        // data-zanim-trigger="scroll"
      >
        <div className="container-fluid mask-dark">
          <div className="header-overlay"></div>
          <div className="position-relative pt-10 pb-8">
            <div className="header-text">
              <div className="overflow-hidden">
                <h1
                  className="display-3 text-white font-weight-light ls-1"
                  //   data-zanim-xs='{"duration":2,"delay":0}'
                >
                  Terrenos
                </h1>
              </div>
              <div className="overflow-hidden">
                <div
                  className="d-flex justify-content-center"
                  //   data-zanim-xs='{"duration":2,"delay":0.1}'
                >
                  <p className="text-uppercase ls-1 text-white ">
                    Terrenos en ubicaciones estratégicas para tus proyectos
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TerrenosBanner;
