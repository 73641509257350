import logo from './logo.svg';
import './App.css';

import Landing from './layouts/landing';
function App() {
  return (
    <div >
      <Landing />
    </div>
  );
}

export default App;
