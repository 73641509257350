import React, { Component } from "react";
import Typed from "react-typed";
import { Row, Col, Button, Card, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../src/index.css";
import "../../assets/css/theme.css";

import penicon from "../../assets/img/line-icons/fountain-pen-3.svg";
import pinicon from "../../assets/img/line-icons/placeholder-2.svg";
import finicon from "../../assets/img/line-icons/bar-chart-1.svg";

import docsicon from "../../assets/img/line-icons/id-card.svg";

const Proceso = () => {
  return (
    <Container className="py-6">
      <div className="row">
        <div
          className="col mb-4 text-center"
          // data-zanim-timeline="{}"
          // data-zanim-trigger="scroll"
        >
          <div className="overflow-hidden">
            <h2
              className="fs-md-5"
              data-zanim-xs='{"duration":"1.5","delay":0}'
            >
              procedimiento
            </h2>
          </div>
          <div className="overflow-hidden">
            <p
              className="fs--1 text-uppercase text-black ls-0 mb-0"
              data-zanim-xs='{"duration":"1.5","delay":0.1}'
            >
              ¿Cual es el proceso?
            </p>
          </div>
          <div className="overflow-hidden">
            <hr
              className="hr-short border-black"
              data-zanim-xs='{"duration":"1.5","delay":0.2}'
            />
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-9 px-5">
          <div
            className="media process-item border-dashed-left border-600 pb-5"
            // data-zanim-timeline="{}"
            // data-zanim-trigger="scroll"
          >
            <div className="process-icon-circle">
              <img className="process-icon" src={pinicon} alt="" />
            </div>
            <div className="media-body ml-4 ml-sm-5">
              <h5 className="ls-1">
                <span className="bg-white pr-3">encuentra &amp; elige</span>
              </h5>
              <p
                className="mb-0"
                data-zanim-xs='{"from":{"opacity":0},"to":{"opacity":1},"duration":1,"delay":0.2}'
              >
                Checa las propiedades que tenemos disponibles. Puedes
                contactactarnos en caso de tener duda y te asesoraremos para
                recomendarte la propiedad ideal.
              </p>
            </div>
          </div>
          <div
            className="
              media
              process-item
              border-dashed-left
              border-600
              border-md-left-0
              border-md-dashed-right
              pb-5
            "
            // data-zanim-timeline="{}"
            // data-zanim-trigger="scroll"
          >
            <div
              className="
                media-body
                position-relative
                ml-4 ml-sm-5 ml-md-0
                mr-md-5
              "
            >
              <h5 className="text-md-right">
                <span className="bg-white pl-md-3">
                  Requisitos y documentos
                </span>
                <span className="process-devider border-right-0 l-0"></span>
              </h5>
              <p
                className="mb-0 text-md-right"
                data-zanim-xs='{"from":{"opacity":0},"to":{"opacity":1},"duration":1,"delay":0.3}'
              >
                Junta todos los documentos y requisitos necesarios para la
                propiedad que quieres rentar o comprar.
              </p>
            </div>
            <div className="process-icon-circle">
              <img className="process-icon" src={docsicon} alt="" />
            </div>
          </div>
          <div
            className="media process-item border-dashed-left border-600 pb-5"
            // data-zanim-timeline="{}"
            // data-zanim-trigger="scroll"
          >
            <div className="process-icon-circle">
              <img className="process-icon" src={penicon} alt="" />
            </div>
            <div className="media-body position-relative ml-4 ml-sm-5">
              <span className="process-devider border-left-0 r-0"></span>
              <h5 className="ls-1">
                <span className="bg-white pr-3">Aprobación &amp; Firma</span>
              </h5>
              <p
                className="mb-0"
                data-zanim-xs='{"from":{"opacity":0},"to":{"opacity":1},"duration":1,"delay":0.4}'
              >
                Después de recolectar y aprobar todos los documentos y
                requisitos, firmamos ante notario.
              </p>
            </div>
          </div>
          <div
            className="media process-item"
            // data-zanim-timeline="{}"
            // data-zanim-trigger="scroll"
          >
            <div
              className="
                media-body
                position-relative
                ml-4 ml-sm-5 ml-md-0
                mr-md-5
              "
            >
              <h5 className="text-md-right">
                <span className="bg-white pl-md-3">Empieza &amp; crece</span>
                <span className="process-devider border-right-0 l-0"></span>
              </h5>
              <p
                className="mb-0 text-md-right"
                data-zanim-xs='{"from":{"opacity":0},"to":{"opacity":1},"duration":1,"delay":0.5}'
              >
                Puedes hacer uso de la propiedad según sea el caso.
              </p>
            </div>
            <div className="process-icon-circle">
              <img className="process-icon" src={finicon} alt="" />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Proceso;
